import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/SEO";

import { navigate } from "gatsby-link";

const AndroidTemplate = ({ data }) => {
  const { androidLink } = data.wp.post.properties.assets.qrCode;
  const logoUrl = data.wp.pages.nodes[1].home.mainInfo.logo.mediaItemUrl;

  setTimeout(() => {
    navigate(androidLink);
    console.log(androidLink);
  }, 500);

  return (
    <>
    <SEO title={`${data.wp.post.title} - Android`} />
      <div className="redirect-template">
        <div className="logo">
          <img src={logoUrl} alt="logo" />
        </div>
        <h2 style={{ marginTop: "26px" }}>Loading....</h2>
      </div>
    </>
  );
};

export const query = graphql`
  query GetPostAndroid($slug: ID!) {
    wp {
      pages {
        nodes {
          home {
            mainInfo {
              logo {
                mediaItemUrl
              }
            }
          }
        }
      }

      post(idType: SLUG, id: $slug) {
        title
        properties {
          assets {
            qrCode {
              androidLink
            }
          }
        }
      }
    }
  }
`;

export default AndroidTemplate;
